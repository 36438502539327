.dashboard svg {
    font-size: 5rem;
}

.dashboard .col {
    padding: 25px;
}

.dashboard .card {
    height: 100%;
}